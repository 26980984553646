import React, { Component } from "react";
import { Parallax } from 'react-scroll-parallax';

class Header extends Component {

   render() {
      const p = this.props;
      const headerSubTitle = p.headerSubtitle.map((value) =>
         <h3 className="semi" key={value}>{value}</h3>
      );
      const headerTexts = this.props.headerText.map((value) =>
         <p key={value}>{value}</p>
      );

      return (
         <div className={"container-fluid header " + this.props.headerClass}>
            <ParallaxImage />
            <div className="container position-relative h-100">
               <div className="col-md-12 header-intro text-right color-white position-absolute">
                  <ParralaxLogo />
                  <h2>{this.props.headerTitle}</h2>
                  {headerSubTitle}
                  <h4 className="accent-light">{this.props.headerTags}</h4>
                  <div className="accent-light font-weight-bold headerText">
                     {headerTexts}
                  </div>

               </div>

            </div>
         </div>
      )
   }
}

const ParallaxImage = () => (
   <Parallax className="intro-image" x={[0, -20]} tagOuter="figure">
      <img src={require('../../images/intro_image_horse.png')} alt='Ruiterlijk genoegen'></img>
   </Parallax>
);

const ParralaxLogo = () => (
   <Parallax y={[70, -50]} tagOuter="figure">
      <img src={require('../../images/logo.png')} alt='Ruiterlijk genoegen'></img>
   </Parallax>
)

export default Header;