import React, { Component, useState, useRef } from "react";
import { NavLink } from 'react-router-dom';

class Navigation extends Component {
   state = {
      isTop: true,
    };

   componentDidMount() {
      document.addEventListener('scroll', () => {
         const isTop = window.scrollY < 100;
         if (isTop !== this.state.isTop) {
            this.setState({ isTop })
         }
      });
   }

   toggleNav = (e) => {
      let navigation = document.getElementById('navigation');
      let toggler = document.getElementById('navToggler');
      navigation.classList.toggle("active");
      toggler.classList.toggle("active");
   }



   render() {
     
      return (
         <div className={this.state.isTop ? 'top' : 'scrolled'} >

            <nav className="navbar fixed-top bg-black deco-font" >
               <div className="navToggler" id="navToggler" onClick={this.toggleNav}>
                  <span className="first"></span>
                  <span className="second"></span>
                  <span className="third"></span>
               </div>
               <div className="navigation" id="navigation">
                  <div className="container position-relative">

                     <ul className="navbarList position-relative">
                        <li className="navItem" onClick={this.toggleNav}>
                           <NavLink exact={true} className="nav-link" activeClassName="current" to="/"> Home </NavLink>
                        </li>
                        <li className="navItem" onClick={this.toggleNav}>
                           <NavLink exact={true} className="nav-link" activeClassName="current" to="over-ons"> Over ons </NavLink>
                        </li>
                        <li className="navItem" onClick={this.toggleNav}>
                           <NavLink exact={true} className="nav-link" activeClassName="current" to="fotogalerij"> Fotogalerij </NavLink>
                        </li>
                        <li className="navItem" onClick={this.toggleNav}>
                           <NavLink exact={true} className="nav-link" activeClassName="current" to="contacteer-ons"> Contacteer ons </NavLink>
                        </li>
                     </ul>

                     <div className="logo">
                        <img src={require('../images/logo.png')} alt=""/>
                     </div>

                  </div>

               </div>
            </nav>
         </div>
      )
   }
}

export default Navigation;