import React, { Component, useState } from 'react';
import Header from './helpers/header';
import ContactBanner from './helpers/contactBanner';
import VideoModal from './videoModal';
import {Link} from 'react-router-dom';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

class Home extends Component{

   render(){
     
      const firm = "Hippisch centrum Ruiterlijk Genoegen";
      return(
         <div id="home">
            <Header 
               headerClass="homeHeader"
               headerTitle={firm}
               headerSubtitle={["allround ruitersportcentrum gelegen", "in het landelijke Oud-Turnhout."]}
               headerTags="correctheid, integer, zuiver & duidelijk"
               headerText={["Is waar wij naar streven in de omgang met het paard en met elkaar"]}
            />

            <div className="container">
               <div className="row h-100 home-about">
                  <div className="col-sm-12 col-md-4 col-lg-3">
                     <div className="h-100">
                          <ParralaxTitle />
                     </div>
                     
                  </div>

                  <div className="col-sm-12 col-md-8 col-lg-9 my-auto">
                     <div className="home-about-intro">
                        <h1>
                           {firm}
                           <span className="accent-light">
                              De zussen Dominique en Brigitte van de Sande 
                           </span>
                        </h1>
                        <p className="mt-5">
                           De zussen Dominique en Brigitte van de Sande hebben  vanuit hun visie deze accommodatie ontworpen, ingericht en baten ze intussen hiernaar uit.  De naam Ruiterlijk Genoegen is gekozen om de bedoeling van het hele gebeuren nog iets meer te schetsen: Ruiterlijk; als in ruiters, maar ook als in correctheid, integer, zuiver, duidelijk.. Zo wij met paarden omgaan en dat ook graag uitdragen in onze lessen, omgang met pensionpaarden, met paardenmensen in het algemeen. Genoegen; het moet een genoegen zijn bij ons te komen, je moet je er prettig en op je gemak voelen, er rust vinden, er graag vertoeven. Dit geldt zowel voor de paarden als voor de mensen.
                        </p>
                        <div className="row">
                           <div className="col-md-auto mt-5">
                              <Link to="over-ons" className="button dark mt-5">Meer informatie</Link>
                           </div>
                           <div className="col-md-auto mt-5">
                              <VideoModal />
                           </div>
                        </div>
                     
                     </div>

                  </div>


               </div>
            </div>
            <ImageBanner />
            <ContactBanner 
               title="Iedereen welkom, kom zeker eens kijken." 
               button="Meer informatie" 
               link="contacteer-ons"
               class="black"
            />
         </div>
      )
   }
}

const ImageBanner = () =>{
   return(
      <div className="container-fluid imageBanner">
         <div className="darkenOverlay"></div>
      </div>
   )
}

const ParralaxTitle = () =>{
   return(
      <Parallax y={[15, -50]} tagOuter="figure">
          <h1 className="croocked accent-light">
            Over ons
         </h1>
      </Parallax>
   )
}

export default Home;