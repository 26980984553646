import React, { Component, useRef, useEffect } from "react";
import { useIntersection } from "react-use";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import ContactBanner from "./helpers/contactBanner";
import VideoBanner from "./helpers/videoBanner";

import { gsap } from "gsap";

const Over = () => {
  const sectionOne = useRef(null);
  const sectionTwo = useRef(null);
  const sectionThree = useRef(null);
  const sectionFour = useRef(null);

  const intersection = useIntersection(sectionOne, {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  });

  const intersectionTwo = useIntersection(sectionTwo, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });

  const intersectionThree = useIntersection(sectionThree, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });

  const intersectionFour = useIntersection(sectionFour, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });

  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: 60,
      ease: "power3.out",
      stagger: {
        amount: 0.3,
      },
    });
  };

  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: 20,
      ease: "power3.out",
      stagger: {
        amount: 0.3,
      },
    });
  };

  intersection && intersection.intersectionRatio < 0.3
    ? fadeOut(".fadeIn")
    : fadeIn(".fadeIn");

  intersectionTwo && intersectionTwo.intersectionRatio < 0.5
    ? fadeOut(".fadeInTwo")
    : fadeIn(".fadeInTwo");

  intersectionThree && intersectionThree.intersectionRatio < 0.5
    ? fadeOut(".fadeInThree")
    : fadeIn(".fadeInThree");

  intersectionFour && intersectionFour.intersectionRatio < 0.5
    ? fadeOut(".fadeInThree")
    : fadeIn(".fadeInThree");

  const ParallaxImage = () => (
    <img
      src={require("../images/h.jpg")}
      alt="Ruiterlijk genoegen"
      className="mw-100 par-img"
    />
  );

  const ParralaxIntro = () => (
    <Parallax y={[100, -50]} tagOuter="figure">
      <h2 className="mb-4 big-title accent-dark">'t Ruiterlijk Genoegen</h2>
      <h4 className="mb-5">
        Allround ruitersportcentrum gelegen in het landelijke Oud-Turnhout.{" "}
      </h4>
    </Parallax>
  );

  return (
    <div id="about">
      <div className="container-fluid no-header medium about-header">
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-auto">
              <ParralaxIntro />
            </div>
            <div className="col-md-6">{/* <ParallaxImage /> */}</div>
          </div>
        </div>
      </div>

      <div className="container bg-white mb-5" ref={sectionOne}>
        <div className="row">
          <div className="col-md-6 pt-5 my-auto">
            <h4 className="accent-dark font-weight-bold deco-font mb-4 fadeIn">
              De zussen Dominique en <br /> Brigitte van de Sande{" "}
            </h4>
            <p className="fadeIn">
              Vanuit hun visie werd deze accommodatie ontworpen, ingericht en
              baten ze intussen hiernaar uit.<br></br>De naam Ruiterlijk
              Genoegen is gekozen om de bedoeling van het hele gebeuren nog iets
              meer te schetsen: Ruiterlijk; als in ruiters, maar ook als in{" "}
              <span className="font-weight-bold accent-light">
                correctheid, integer, zuiver, duidelijk..
              </span>{" "}
              Zo wij met paarden omgaan en dat ook graag uitdragen in onze
              lessen, omgang met pensionpaarden, met paardenmensen in het
              algemeen.<br></br>
              <br></br> Genoegen; het moet een genoegen zijn bij ons te komen,
              je moet je er prettig en op je gemak voelen, er rust vinden, er
              graag vertoeven. Dit geldt zowel voor de paarden als voor de
              mensen.
            </p>
          </div>
          <div className="col-md-6 position-relative">
            <img
              src={require("../images/h.jpg")}
              alt="Ruiterlijk genoegen"
              className="mw-100 par-img fadeIn"
            />
          </div>
        </div>
      </div>

      <div className="container mb-5" ref={sectionTwo}>
        <div className="row">
          <div className="col-md-6 pt-5 my-auto fadeInTwo">
            <h4 className="accent-dark font-weight-bold deco-font mb-4">
              De accommodatie is uitgerust<br></br> met 50 ruime en lichte
              boxen.
            </h4>
            <p>
              Er kan gekozen worden voor een box zonder of met buitenluik of een
              luxestal met binnen en buitenluik. Iedere stal heeft wel een
              contactluikje. Er is een mooie binnenpiste van 20 m x 62 m en een
              buitenpiste van 50 x 70 m, met springparcours, en een
              longeercirkel van 18 meter diameter. Allemaal op eb en
              vloedsysteem. Daarnaast beschikken we over een 6-paards stapmolen,
              enkele all weatherpaddocks en weilanden, een groot eigen
              parkeerterrein. Tevens is er een gezellige bar met ruim terras, en
              weids uitzicht, aanwezig. Ook overnachten in een van onze fijne
              kamers met eigen douchekamer hoort tot de mogelijkheden. Grotere
              groepen kunnen wij tevens slaapgelegenheid aanbieden!
            </p>
          </div>
          <div className="col-md-6 position-relative fadeInTwo">
            <img
              src={require("../images/s.jpg")}
              alt="Ruiterlijk genoegen"
              className="mw-100 par-img"
            ></img>
          </div>
        </div>
      </div>

      <div className="container mb-5" ref={sectionThree}>
        <div className="row mb-5">
          <div className="col-md-6 pt-5 my-auto fadeInThree">
            <h4 className="accent-dark font-weight-bold deco-font mb-4">
              pensionstalling, dressuurlessen, manegelessen &
              trainingsaccomodatie
            </h4>
            <p>
              Wij bieden volpension pensionstalling en een trainingsaccommodatie
              voor zowel de spring -als dressuurruiter. Daarnaast voorzien we,
              op kleine schaal, manegelessen waar de klemtoon ligt op een juiste
              manier leren paardrijden met aandacht voor het wezen paard. Zowel
              de accommodatie als de lessen zijn ook voor de externe ruiter te
              boeken middels afspraak per email of telefoon. Onze dressuurlessen
              zijn gebaseerd op harmonie en vertrouwen tussen ruiter en paard,
              op rijden met gevoel steeds vanuit positieve bevestiging. We
              zetten vooral in op een fijne, correcte manier van rijden én
              scholing die voor zowel het paard als de ruiter als plezierig
              wordt ervaren. Zo halen we het beste in de lesklant naar boven en
              dragen we positief bij aan onze mooie sport en het paardenwelzijn!
            </p>
          </div>
          <div className="col-md-6 position-relative fadeInThree">
            <img
              src={require("../images/b.jpg")}
              alt="Ruiterlijk genoegen"
              className="mw-100 par-img"
            ></img>
          </div>
        </div>
      </div>

      <div className="container mb-5" ref={sectionFour}>
        <div className="row mb-5">
          <VideoBanner />
        </div>
      </div>

      <div className="divider d-160"></div>

      <ContactBanner
        title="Iedereen welkom, kom zeker eens kijken."
        button="Meer informatie"
        link="contacteer-ons"
        class="black"
      />
    </div>
  );
};

export default Over;
