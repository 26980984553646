import React, {Component} from "react";
import {Link} from 'react-router-dom';

class ContactBanner extends Component{
   render(){

      return(
         <div className="container-fluid bg-accent contactBanner">
            <div className="container">

               <div className="row">
                  <div className="col-lg-10 col-md-8">
                     <h2 className="deco-font">{this.props.title}</h2>
                  </div>
                  <div className="col-lg-2 col-md-4 my-auto">
                     <Link to={this.props.link} className={"button " + this.props.class}  >{this.props.button}</Link>
                  </div>   
               </div>
              
            </div>   
         </div>
      )
   }
}

export default ContactBanner;