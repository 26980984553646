import React, { Component } from "react";
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes, Navigation, Footer } from "./components";
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollToTop from './components/scrollTop';



class App extends Component {
   
   render() {

      return (
         
         <ParallaxProvider>

            <div className="App">
               <Router>
               <ScrollToTop />
                  <Navigation />
                  <Routes />
                  <Footer />
               </Router>
            </div>
         </ParallaxProvider>

      )
   }
}

export default App;
