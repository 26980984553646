import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    let d = new Date();
    let day = d.getDay();

    super(props);

    this.state = {
      today: day,
      openingsHours: ["Gesloten", "17:00 - 21:00", "17:00 - 21:00"],
    };
  }

  render() {
    const hours = [
      { id: 1, day: "Maandag", value: "Gesloten" },
      { id: 2, day: "Dinsdag", value: "17:00 - 21:30" },
      { id: 3, day: "Woensdag", value: "13:00 - 21:30" },
      { id: 4, day: "Donderdag", value: "17:00 - 21:30" },
      { id: 5, day: "Vrijdag", value: "17:00 - 21:30" },
      { id: 6, day: "Zaterdag", value: "10:30 - 19:00" },
      { id: 7, day: "Zondag", value: "10:30 - 19:00" },
    ];

    function Openingsuren(props) {
      return props.hours.map((hour) => (
        <li key={hour.id} className="hours">
          {hour.day}
          <span>{hour.value}</span>
        </li>
      ));
    }

    return (
      <footer>
        <div className="container footer">
          <img
            src={require("../images/footer_horse.png")}
            className="accent-image"
            alt=""
          />

          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 align-self-center">
              <h3 className="deco-font">Openingsuren</h3>
              <p>Iedereen welkom tijdens deze uren:</p>
              <ul className="list-unstyled">
                <Openingsuren hours={hours} today={this.state.today - 1} />
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h3 className="deco-font">Waar kan u ons vinden</h3>
              <p>Hippisch Centrum Ruiterlijk Genoegen</p>

              <ul className="list-unstyled">
                <li>De Hoogt 40</li>
                <li>2360 Oud-Turnhout</li>
                <li>
                  <a href="mailto:hcrg.info@gmail.com">hcrg.info@gmail.com</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h3 className="deco-font">Navigatie</h3>
              <ul className="list-unstyled">
                <li>
                  <NavLink exact={true} activeClassName="current" to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="current"
                    to="/over-ons"
                  >
                    Over ons
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="current"
                    to="/fotogalerij"
                  >
                    Fotogalerij
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="current"
                    to="/contacteer-ons"
                  >
                    Contacteer ons
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="copyright">
            &copy;{" "}
            <a href="https://cmctelecom.be/ict/service/webdesign">
              CMC Web | 2020{" "}
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
