import React, {useState } from "react";
import { IoCloseOutline, IoPlayCircleOutline} from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";


const VideoModal = () => {
   const [modal, setModal] = useState(false);
      const [videoLoading, setVideoLoading] = useState(true);

      const openModal = () => {
         setModal(!modal);
       };
     
       const spinner = () => {
         setVideoLoading(!videoLoading);
       };

      return(
         <>
            <a onClick={openModal} className="button light"><IoPlayCircleOutline /> Bedrijfsvideo
            {modal ? (
               <section className="modal__bg">
                  <div className="modal__align">
                     <div className="modal__content" modal={modal}>
                        <IoCloseOutline
                           className="modal__close"
                           arial-label="Close modal"
                           onClick={setModal}
                        />
                        <div className="modal__video-align">
                           {videoLoading ? (
                           <div className="modal__spinner">
                              <BiLoaderAlt
                                 className="modal__spinner-style"
                                 fadeIn="none"
                              />
                           </div>
                           ) : null}
                          
                           <video 
                              width="800" 
                              height="500" 
                              controls 
                              className="modal__video-style" 
                              onLoad={spinner}
                              loading="lazy"
                              autoPlay>
                              <source src="./videos/Ruiterlijk_Genoegen_Website_v21655054797694.mp4" type="video/mp4"/>
                           </video>
                        </div>
                     </div>
                  </div>
               </section>
            ) : null}
         </a>
      </>
   )
}

export default VideoModal;