import React, {Component} from "react"
import { Route } from "react-router-dom"
import {
   Navigation,
   Home,
   Over,
   Galerij,
   Contact
} from "./";

class Routes extends Component {
   render(){
      return(
         <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/over-ons" component={Over} />
            <Route exact path="/fotogalerij" component={Galerij} />
            <Route exact path="/contacteer-ons" component={Contact} />
         </div>
      )   
   }
   
}

export default Routes