import React, { useRef, useEffect } from "react";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import { Link } from "react-router-dom";
import { TweenMax } from "gsap";
import { IoPhonePortraitOutline, IoMailOutline } from "react-icons/io5";

const Contact = () => {
  let contactContainer = useRef(null);
  let contactMap = useRef(null);

  useEffect(() => {
    TweenMax.from(contactContainer, 1, {
      opacity: 0,
      x: -160,
      ease: "power3.out",
      stagger: {
        amount: 0.3,
      },
    });

    TweenMax.from(contactMap, 1, {
      opacity: 0,
      x: -250,
      ease: "power3.out",
      stagger: {
        amount: 0.3,
      },
      delay: 0.5,
    });
  });

  // intersection && intersection.intersectionRatio <  ?
  //    slideOut("slideIn")
  // :  slideIn("slideIn")

  const hours = [
    { id: 1, day: "Maandag", value: "Gesloten" },
    { id: 2, day: "Dinsdag", value: "17:00 - 21:30" },
    { id: 3, day: "Woensdag", value: "13:00 - 21:30" },
    { id: 4, day: "Donderdag", value: "17:00 - 21:30" },
    { id: 5, day: "Vrijdag", value: "17:00 - 21:30" },
    { id: 6, day: "Zaterdag", value: "10:30 - 19:00" },
    { id: 7, day: "Zondag", value: "10:30 - 19:00" },
  ];

  function Openingsuren(props) {
    // let today = this.state.today
    // let h = (this.state.openingsHours)

    const content = props.hours.map((hour) => (
      <li key={hour.id} className="hours">
        {hour.day}
        <span>{hour.value}</span>
      </li>
    ));

    return content;
  }

  const ParralaxIntro = () => (
    <Parallax y={[100, -50]} tagOuter="figure">
      <h2 className="mb-4 big-title accent-dark">Contacteer ons</h2>
      <h4 className="mb-5">
        Heeft u nog vragen of wenst u meer informatie? Twijfel niet om ons te
        contacteren.
      </h4>
    </Parallax>
  );

  const Map = () => (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2493.220140793192!2d4.975646415920033!3d51.325470631809594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b3e21face86f%3A0x4bb9ab1ee77d821b!2sDe%20Hoogt%2040%2C%202360%20Oud-Turnhout!5e0!3m2!1sen!2sbe!4v1588919137646!5m2!1sen!2sbe"
      frameborder="0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
      width="100%"
      height="500px"
      title="map"
    ></iframe>
  );

  return (
    <div id="contact">
      <div className="container-fluid no-header medium bg-accent contact-header">
        {/* <Map /> */}
        <div className="overlay"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-6 my-auto no-clicks">
              <ParralaxIntro />
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-5 col-md-12">
            <p>
              Hippisch centrum Ruiterlijk Genoegen is een allround
              ruitersportcentrum gelegen in het landelijke Oud-Turnhout.
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div
            className="col-md-6 col-sm-12"
            ref={(el) => {
              contactContainer = el;
            }}
          >
            <h3 className="accent-light font-weight-bold">Contactgegevens</h3>
            <ul className="list-unstyled">
              <li>
                <IoPhonePortraitOutline /> 0485 554 130
              </li>
              <li>
                <IoMailOutline />{" "}
                <a href="mailto:hcrg.info@gmail.com">hcrg.info@gmail.com</a>
              </li>
            </ul>
            <h3 className="accent-light font-weight-bold">Openingsuren</h3>
            <ul className="list-unstyled">
              <Openingsuren hours={hours} />
            </ul>
            <p>
              Tijdens bovenstaande uren is iedereen welkom een kijkje te komen
              nemen en een lekker glaasje te komen drinken.
            </p>
            <p>
              Voor onze pensiongasten zijn de openingsuren uiteraard ruimer.
            </p>
            <p>Wil je op de hoogte blijven van events en nieuwtjes?</p>
            <a
              href="https://www.facebook.com/pages/category/Amateur-Sports-Team/Hippisch-Centrum-Ruiterlijk-Genoegen-100933484660642/"
              className="button fb-button mt-2"
            >
              Volg ons op Facebook
              <img src={require("../images/facebook-official.svg")} alt="" />
              <span className="bg-fb"></span>
            </a>
          </div>
          <div
            className="col-md-6 col-sm-12 map"
            ref={(el) => {
              contactMap = el;
            }}
          >
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
