import React, { Component, useRef } from "react";
import Masonry from "react-masonry-css";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";

const Galerij = () => {
  let array = [
    "b",
    "h",
    "s",
    "40930467",
    "IMG_20160727_224512",
    "IMG_20190611_145932_resized_20200211_033755340 2",
    "IMG_20191227_151809_resized_20200211_033756788 2",
    "kantine",
    "kantine1",
    "donillus",
    "dresswedstrijd",
    "koffiemet",
    "wedstrijdparcours",
  ];

  let images = array.map((image, index) => {
    return (
      <img
        key={index}
        src={require(`../images/gallery/${image}.jpg`)}
        alt=""
        className="mw-100"
      />
    );
  });

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const ParralaxIntro = () => (
    <Parallax y={[100, -50]} tagOuter="figure">
      <h2 className="mb-4 big-title accent-dark">Fotogalerij</h2>
      <h4 className="mb-5">
        Wij bieden pensionstalling, dressuurlessen, op kleine schaal
        manegelessen en trainingsaccomodatie aan.
        <br />
        Hieronder enkele sfeerbeelden.
      </h4>
    </Parallax>
  );

  return (
    <div id="gallerij">
      <div className="container-fluid no-header small">
        <div className="container">
          <div className="row">
            <div className="col-md-8 my-auto">
              <ParralaxIntro />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {images}
        </Masonry>
      </div>
    </div>
  );
};

export default Galerij;
