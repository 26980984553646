import React from "react";

const VideoBanner = () => {
   return(
      <div className="row mb-5">
         <div className="col-lg-6 pt-5 my-auto fadeInFour">
            <video
               controls 
               className="about_video" 
               loading="lazy"
               poster="./videos/video_1.png"
            >
               <source src="./videos/Ruiterlijk_Genoegen_Website_v21655054797694.mp4" type="video/mp4"/>
            </video>
         </div>
         <div className="col-lg-6 pt-5 my-auto fadeInFour">
            <video
               controls 
               className="about_video" 
               loading="lazy"
               poster="./videos/video_2.png"
            >
               <source src="./videos/Ruiterlijk_Genoegen_Facebook1655054797675.mp4" type="video/mp4"/>
            </video>
         </div>
      </div>
   )
}

export default VideoBanner;